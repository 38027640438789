export default function () {
  const { $algolia, $sitewideConfig } = useNuxtApp()
  const dealerLocator = $algolia.searchContexts.dealerLocator

  interface DealerLocatorState {
    location?: string
    radius: number
    dealerFocus?: string
  }

  const state = useState<DealerLocatorState>('dealerLocatorState', () => ({
    radius: 25,
  }))

  const getDealerRoute = (brand?: string) => {
    let path = '/dealer-locator/'

    if ($sitewideConfig.config.dealerLocatorShowBrandFacet && brand) path += `?brand=${brand}`

    return path
  }

  const showDealerLocatorOnProductPage = (brand?: string) => {
    // Should we show the link?
    const isFeatureEnabled =
      $sitewideConfig.config.dealerLocatorEnabled && $sitewideConfig.config.dealerLocatorShowProductPageLink

    // If the madeByRT stuff is enabled then include that in the decision to show the link.
    if (brand && $sitewideConfig.config.madeByRealTruckEnabled)
      return isFeatureEnabled && $sitewideConfig.config.madeByRealTruckBrands?.data.includes(brand)

    return isFeatureEnabled
  }

  async function setLocation(lat: number, lng: number, address?: string) {
    // Set the location values if the values aren't nullish otherwise fallback to 0, 0 so we don't get any results
    dealerLocator.setLocation(lat ?? 0, lng ?? 0)
    state.value.location = address
  }

  function setRadius(radius: number) {
    state.value.radius = radius
    dealerLocator.setRadius(radius)
  }

  async function setBrand(brandName: string) {
    if (!brandName) return

    await dealerLocator.indexes.forEach((index) => {
      index.clearRefinements()
      index.addRefinements('brands.brand', brandName)
      let preferredBrandsFacet = 'preferredBrands'
      if (index.key === 'brand') preferredBrandsFacet = `NOT ${preferredBrandsFacet}`
      index.addRefinements(preferredBrandsFacet, brandName)
    })
  }

  function setDealerFocus(id: string) {
    state.value.dealerFocus = id
  }

  async function performSearch() {
    // We must at least have a location to search
    if (!state.value.location) return
    await dealerLocator.search()
  }

  return {
    state,
    getDealerRoute,
    showDealerLocatorOnProductPage,
    setRadius,
    setBrand,
    setDealerFocus,
    setLocation,
    performSearch,
  }
}
